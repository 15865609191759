.cart,.product-flag,.product-flags{display:none;}
.pt_custommenu .pt_menu .popup .block1 .column .itemMenuName{word-wrap:normal !important;}
.pt_custommenu .pt_menu .popup,.pt_custommenu .pt_menu .popup .block1{width:360px !important; }

.banner-center .block-wrapper::before {content: url('spain.png');
/*background-image:url('spain.png');background-repeat: no-repeat;background-position: top center;*/
}

.banner-center .block-wrapper.wrapper2::before{content: url('leather.png');}
.banner-center .block-wrapper.wrapper3::before {content: url('me-gusta_white.png');}

.banner-top .box-col .col-text .box h3::before {content:"" !important;}